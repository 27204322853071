<template>
  <div>
    <div class="register-form-wrap">
      <div class="sctp-container clear content sctp-bg-white sctp-mar-tb10">
        <div class="flex" style="padding: 0 40px;">
          <div style="width: 200px;border-right: 2px solid #C0C4CC;">
            <el-image
              style="width: 150px;height: 150px;margin-bottom: 20px;"
              fit="cover"
              :src="registerImageUrl"
            ></el-image>
            <div class="pad-b20 fz-16">账号注册中心</div>
            <el-steps class="register-step" direction="vertical" :active="step">
              <el-step title="填写账号信息"></el-step>
              <el-step title="验证信息"></el-step>
              <el-step title="注册完成"></el-step>
            </el-steps>
          </div>
          <div class="register-from-wrap">
            <template v-if="step === 1">
              <el-form ref="infoForm" :model="infoForm" label-width="100px" :rules="infoFormRules">
                <el-form-item class="custom-radio" prop="account_type">
                  <div class="flex">
                    <div
                      @click="infoForm.account_type = 1"
                      :class="{active: infoForm.account_type === 1}"
                      class="flex-item1 account-type-item">
                      <i class="el-icon-user"></i>
                      <span>个人账户</span>
                    </div>
                    <div
                      @click="infoForm.account_type = 2"
                      :class="{active: infoForm.account_type === 2}"
                      class="flex-item1 account-type-item">
                      <i class="el-icon-s-check"></i>
                      <span>企业账户</span>
                    </div>
                    <div
                      @click="infoForm.account_type = 4"
                      :class="{active: infoForm.account_type === 4}"
                      class="flex-item1 account-type-item">
                      <i class="el-icon-s-custom"></i>
                      <span>个体工商户</span>
                    </div>
                  </div>
                  <!--                  <el-radio-group v-model="infoForm.account_type">-->
                  <!--                    <el-radio :label="1" border>个人</el-radio>-->
                  <!--                    <el-radio :label="2" border>企业</el-radio>-->
                  <!--                    <el-radio :label="4" border>个体工商户</el-radio>-->
                  <!--                  </el-radio-group>-->
                </el-form-item>
                <el-form-item label="注册账号" prop="account">
                  <el-input placeholder="4—20位字母、数字或下划线组合，不能为纯数字" v-model="infoForm.account">
                    <div slot="append">（系统登录账号）</div>
                  </el-input>
                </el-form-item>
                <el-form-item label="账号密码" prop="password">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="infoForm.password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="rePassword">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="infoForm.rePassword"></el-input>
                </el-form-item>
                <el-form-item label="常用邮箱" prop="email">
                  <el-input v-model="infoForm.email"></el-input>
                </el-form-item>
                <template v-if="infoForm.account_type === 2">
                  <el-form-item label="企业名称" prop="enterprise_name">
                    <el-input v-model="infoForm.enterprise_name"></el-input>
                  </el-form-item>
                </template>
                <template v-if="infoForm.account_type === 4">
                  <el-form-item label="工商户名称" prop="enterprise_name">
                    <el-input v-model="infoForm.enterprise_name"></el-input>
                  </el-form-item>
                </template>
                <el-form-item prop="read">
                  <el-radio-group v-model="infoForm.read">
                    <el-radio label="1">我已阅读并接受
                      <span @click.prevent="centerDialogVisible = true">《{{ websiteTitle }}》</span>
                      用户注册协议
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitRegisterInfo('infoForm')">下一步</el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 2">
              <el-form ref="validateForm" :model="validateForm" label-width="100px"
                       :rules="validateFormRules">
                <el-form-item label="手机号码" prop="phone">
                  <el-input placeholder="请填写您的手机号码" v-model="validateForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <el-input placeholder="请输入验证码" v-model="validateForm.code">
                    <el-button @click="sendCode('validateForm')" :disabled="codeTimer.disabled"
                               slot="append">
                      {{ codeTimer.text }}
                    </el-button>
                  </el-input>
                  <div ref="captcha" class="captcha"></div>
                </el-form-item>
                <el-form-item>
                  <el-button @click="--step">上一步</el-button>
                  <el-button type="primary" @click="submitValidateForm('validateForm')">下一步
                  </el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 3">
              <div style="padding: 70px 0 30px;" class="sctp-tc register-success-container">
                <i class="el-icon-success"></i>
                <div class="sctp-color-main">
                  <div class="sctp-mar-b15">
                    <span class="sctp-mar-r5">注册成功</span>
                    <span><count-down @finish="goPage('/')" format="ss秒后自动跳转首页"
                                      :time="5 * 1000"></count-down></span>
                  </div>
                  <router-link to="/">
                    <el-button type="primary">去首页</el-button>
                  </router-link>
                </div>
              </div>
            </template>
          </div>
        </div>
        <el-dialog
          title="注册协议"
          :visible.sync="centerDialogVisible"
          class="register-dialog"
          center>
          <div class="rich-text" v-html="registrationAgreementContent"></div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import validator from "@/resources/js/validator";
import {captcha, common, config, user} from "@/apis/index";

require('@ASSET/libs/jigsaw/jigsaw.min');
export default {
  name: 'Register',
  components: {
    CountDown: () => import('@CMP/common/CountDown')
  },
  data() {
    const validateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.infoForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    };
    return {
      registerImageUrl: null,
      step: 1,
      infoForm: {
        account: '',
        password: '',
        rePassword: '',
        email: '',
        account_type: 1,
        read: '',
        enterprise_name: '',
        legalperson_name: '',
        bankcard: '',
        business_scope: '',
        business_license: '',
        business_licenselist: ''
      },
      validateForm: {
        phone: '',
        code: ''
      },
      validateFormRules: {
        phone: [
          {required: true, message: '手机号不能为空', trigger: 'change'},
          {
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => /^1[3-9]\d{9}$/.test(value) ? callback()
              : callback(new Error('不支持该手机号'))
          },
          {
            validator: (rule, value, callback) => {
              this.$request.post({
                reqcode: '1139',
                reqdesc: '绑定手机唯一校验',
                phone: value
              }).then(res => {
                const {data} = res;
                if (data) {
                  callback()
                } else {
                  callback(new Error('手机号已被绑定'))
                }
              }).catch(res => {
                if (res) {
                  callback(new Error(res.retDesc))
                }
              })
            }
          }
        ],
        code: [
          {required: true, message: '验证码不能为空', trigger: ['blur', 'change']},
          {len: 6, message: '验证码只能为6位', trigger: ['blur', 'change']}
        ]
      },
      infoFormRules: {
        account_type: [
          {required: true, message: '账户类型不能为空', trigger: ['blur', 'change']}
        ],
        account: [
          {required: true, message: '账户不能为空', trigger: 'blur'},
          {min: 4, max: 20, message: '4—20位字母、数字或下划线组合，不能为纯数字', trigger: ['blur', 'change']},
          {
            required: true,
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              //\u2E80-\u9FFF
              let reg = /^(?!\d+$)[\dA-Za-z_]{4,20}$/;
              // /^(?=.*?[A-Za-z])(?=.*?[0-9])[a-zA-z_]([a-zA-Z0-9_]{3,19})+$/
              reg.test(value)
                ? callback()
                : callback(new Error('4—20位字母、数字或下划线组合，不能为纯数字'))
            }
          },
          {
            validator: (rule, value, callback) => {
              user.checkAccountUniqueness({
                account: value
              }).then(res => {
                const {data} = res;
                if (data) {
                  callback(new Error('账号已存在'))
                } else {
                  callback()
                }
              }).catch(res => {
                if (res) {
                  callback(new Error('校验异常'))
                }
              })
            }
          }
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: 'blur'}
        ],
        rePassword: [
          {required: true, message: '确认密码不能为空', trigger: 'blur'},
          {validator: validateRePass, trigger: 'blur'}
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              }
              if (value) {
                if (!validator.validEmail(value)) {
                  callback(new Error('邮箱格式不正确'));
                  return;
                }
                user.checkEmailUniqueness({
                  userId: (this.user && this.user.userId) || null,
                  email: value,
                }).then(res => {
                  let {data} = res;
                  if (data) {
                    callback(new Error('邮箱已被注册'));
                  } else {
                    callback()
                  }
                }).catch(err => {
                  if (err) {
                    callback(new Error(err.retDesc));
                  }
                })
              }
            },
            trigger: ['blur', 'change']
          },
        ],
        read: [
          {required: true, message: '请确认您已阅读用户注册协议', trigger: 'change'}
        ],
        enterprise_name: [
          {
            required: true, message: '名称不能为空', trigger: 'blur',
          }
        ],
        legalperson_name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.infoForm.account_type === '2' && !value ? callback(new Error('法人姓名不能为空'))
                : callback()
            }
          }
        ],
        bankcard: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.infoForm.account_type === '2' && !value ? callback(new Error('银行卡号不能为空'))
                : callback()
            }
          }
        ],
        business_scope: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.infoForm.account_type === '2' && !value ? callback(new Error('经营范围不能为空'))
                : callback()
            }
          }
        ],
        business_license: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.infoForm.account_type === '2' && !value ? callback(new Error('请上传营业执照'))
                : callback()
            }
          }
        ]
      },
      codeTimer: {
        time: 60,
        defaultText: '发送验证码',
        text: '发送验证码',
        disabled: false,
        tid: null
      },
      centerDialogVisible: false,
      registrationAgreementContent: null
    }
  },
  created() {
  },
  beforeMount() {
    this.getRegisteredAnimations();
    this.getRegisterImage();
  },
  computed: {
    ...Vuex.mapGetters({
      websiteTitle: 'setting/getWebSiteTitle'
    }),
    title() {
      return 'title'
    }
  },
  mounted() {
  },
  watch: {
    step: {
      handler() {
        [this.$refs['infoForm'], this.$refs['validateForm']].forEach(item => {
          if (item) {
            item.clearValidate();
          }
        })
      }
    },
    'infoForm.account_type': {
      handler(val) {
        if (val === 1) {
          this.infoForm.enterprise_name = '';
          this.infoForm.legalperson_name = '';
          this.infoForm.bankcard = '';
          this.infoForm.business_scope = '';
          this.infoForm.business_license = '';
        }
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const isImage = file.type.startsWith('image');
      const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error('上传Logo图片只能是 图片 格式!')
      }
      if (!isLtSize) {
        this.$message.error('上传Logo大小不能超过 2MB!')
      }
      return isImage && isLtSize && this.$message.info('正在上传') && true
    },
    uploadSuccessPositive(res) {
      if (res.code) {
        const {src, preview} = res;
        this.infoForm.business_license = src;
        this.infoForm.business_licenselist = preview;
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    /**
     * 获取用户协议内容
     */
    getRegisteredAnimations() {
      common.loadProtocol({
        protocolName: '注册协议'
      }).then(res => {
        const {data} = res;
        this.registrationAgreementContent = data
      })
    },
    getRegisterImage() {
      config.loadConfig({key: 'REGISTER_PAGE_IMAGE'})
      .then(res => {
        this.registerImageUrl = res.data;
      })
    },
    submitRegisterInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.nextStep();
        }
      })
    },
    submitValidateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const phone = this.validateForm.phone;
          // 注册用户
          this.$request.post({
            reqcode: '1002',
            reqdesc: '用户注册',
            account: this.infoForm.account,
            password: this.infoForm.password,
            email: this.infoForm.email,
            account_type: this.infoForm.account_type,
            enterprise_name: this.infoForm.enterprise_name,
            legalperson_name: this.infoForm.legalperson_name,
            bankcard: this.infoForm.bankcard,
            business_scope: this.infoForm.business_scope,
            business_license: this.infoForm.business_license,
            phone: phone,
            code: this.validateForm.code
          }).then(res => {
            const user = res.data;
            this.$store.commit('account/setUser', user);
            this.$Bus.$emit('login');
            this.$message.success('注册成功');
            this.nextStep()
          });
          return true
        }
        return false
      })
    },
    stopTimer() {
      if (this.codeTimer.tid) {
        clearInterval(this.codeTimer.tid)
      }
      this.codeTimer.tid = null;
      this.codeTimer.text = this.codeTimer.defaultText;
      this.codeTimer.disabled = false
    },
    startTimer() {
      this.codeTimer.disabled = true;
      let time = this.codeTimer.time;
      this.codeTimer.tid = setInterval(() => {
        --time;
        if (time <= 0) {
          this.stopTimer()
        } else {
          this.codeTimer.text = `${time}秒后重试`
        }
      }, 1000);
    },
    sendCode(formName) {
      this.$refs[formName].validateField('phone', async (errMsg) => {
        if (!errMsg) {
          if (!await this.captcha()) {
            return
          }
          this.startTimer();
          // 发送验证码
          captcha.sendPhoneCode({
            phone: this.validateForm.phone,
            checkNotExist: true,
          }).then(res => {
            this.$message.success('验证码发送成功，请注意查收!');
          }).catch(res => {
            this.stopTimer();
          });
          return true;
        }
        return false;
      })
    },
    nextStep() {
      if (this.step < 3) {
        this.step++
      }
    },
    captcha() {
      return new Promise(resolve => {
        let dom = this.$refs['captcha'];
        if (!dom) {
          this.$message.error('验证码生成失败,请刷新页面重试');
          return;
        }
        window.jigsaw.init({
          el: dom,
          onSuccess: () => {
            dom.innerHTML = '';
            resolve(true)
          },
          onFail: () => {
            // resolve(false);
          },
          onRefresh: () => {
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.count-down {
  color: #F90 !important;
}

.custom-radio {
  width: 700px;
  margin-bottom: 50px;

  /deep/ {
    .el-radio {
      text-align: center;
    }

    .el-form-item__content {
      text-align: center;
      margin-left: 0 !important;

      > div {
        margin-left: -150px;
        margin-top: -10px;
      }
    }
  }
}

.content {
  padding: 30px 0;
}

.account-type-item {
  font-size: 18px;
  background-image: linear-gradient(to bottom, #f9f9f9, #eeeeee);
  padding: 10px 0;
  cursor: pointer;

  &.active {
    background-image: linear-gradient(to bottom, #ffb948, #fb9b3d);
    color: white !important;
  }

  i {
    margin-right: 5px;
  }
}

.register-step {
  height: 200px;
}

.register-from-wrap {
  width: 800px;

  .el-form {
    margin-left: 180px;
    width: 500px;
    margin-top: 10px;
  }

  .el-radio.is-bordered {
    border-radius: unset !important;
  }
}

.register-success-container .el-icon-success {
  font-size: 100px;
  color: #F90;
  margin-bottom: 15px;
}

.el-radio.is-checked a {
  color: #F90 !important;
}

.register-dialog /deep/ .el-dialog__body {

  overflow-y: auto;
}

.captcha {
  position: absolute !important;
  background-color: #FFF;
  box-shadow: 0 0 10px 1px #ccc;
  z-index: 1;
  right: 0;
  top: 45px;
}
</style>
